<template>
	<div class="mhome">
		<div class="mhome-wrap" v-infinite-scroll="handleScroll">
			<div class="mrefund-list">
				<div class="item" v-for="(item,index) in refundList" :key="index">
					<div class="item-head">
						<router-link :to="{ name: 'mStoreInfo', params: { id: item.store_info.store_id }}" class="title">{{item.store_info.store_name}}<i class="el-icon-arrow-right"></i></router-link>
						<div class="type">
							<svg-icon icon-class="refund"></svg-icon>
							<span>{{ item.goods_status!==3?0:1 | statusFilter($t('mobile.filters.refundType')) }}</span>
						</div>
					</div>
					<router-link :to="{ name: 'mRefundInfo', params: { id: item.apply_refund_id }}">
						<div class="item-goods" v-for="(goods,index2) in item.order_info.order_goods" :key="index2">
							<div class="goods-pic"><img :src="goods.goods_image" @error="imageLoadError" alt=""></div>
							<div class="goods-main">
								<div class="name">{{ goods.goods_name }}</div>
								<div class="desc">{{ goods.specification }}</div>
							</div>
							<div class="goods-box">
								<div class="price">￥<span>{{ goods.price }}</span></div>
								<div class="number">x{{ goods.quantity }}</div>
							</div>
						</div>
					</router-link>
					<div class="item-box">
						<div class="cell-label">{{ item.refund_status | statusFilter($t('mobile.filters.refundStatus')) }}</div>
						<div class="cell-block" v-if="item.refund_status == 2">{{ $t('mobile.refund.success') }}￥{{ item.actual_price | priceFilter() }}{{$t('mobile.unit.amount')}}</div>
					</div>
					<div class="item-bottom">
						<!-- <div class="btn" @click="handleDelete(item.apply_refund_id)">{{ $t('mobile.actions.deleteRecord') }}</div> -->
						<router-link :to="{ name: 'mRefundInfo', params: { id: item.apply_refund_id }}" class="btn btn-view">
							{{ $t('mobile.actions.viewInfo') }}</router-link>
					</div>
				</div>
				<el-empty v-if="!refundList.length>0" :description="$t('mobile.empty.nodata')"></el-empty>
			</div>
		</div>
	</div>
</template>

<script>
import { fetchRefundList, deleteRefundRecord } from "@/api/mobile/order"
export default {
	filters: {
		statusFilter(status, option) {
			return option[status]
		}
	},
	data() {
		return {
			refundList: [],
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0,
			pageflag: true,
			isScroll: false
		}
	},
	created() {
		this.getRefundList()
	},
	methods: {
		imageLoadError(e) {
			e.target.src = this.$imageErrorGoods;
			e.target.style.objectFit = 'fill';
		},
		getRefundList() {
			const params = {
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			let loading = this.$loading()
			fetchRefundList(params).then(response => {
				if (response.data.list.length > 0) {
					this.refundList = this.refundList.concat(response.data.list);
				} else {
					this.pageflag = false;
				}
				this.pageTotal = response.data.count
				loading.close()
			})
		},
		handleDelete(id) {
			this.$confirm(this.$t('mobile.confirm.deleteRecord'), '', {
				confirmButtonText: this.$t('mobile.actions.confirm'),
				cancelButtonText: this.$t('mobile.actions.cancel'),
				customClass: 'wap-message-confirm',
				showClose: false,
				closeOnClickModal: false
			}).then(() => {
				deleteRefundRecord({ apply_refund_id: id }).then(() => {
					this.$router.go(0)
				})
			}).catch(() => { })
		},
		handleScroll() {
			if(this.isScroll) {
				this.pageCurr++;
				if (this.pageflag) {
					this.getRefundList()
				}
			} else {
				this.isScroll = true
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.mrefund-list {
	padding: 0.32rem 0.256rem;

	.item {
		border-radius: 0.256rem;
		background-color: #fff;
		padding: 0 0.384rem 0.32rem;
		margin-bottom: 0.32rem;

		.item-head {
			display: flex;
			height: 1.28rem;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 0.256rem;

			.title {
				color: #333333;
				font-size: 0.48rem;
				font-weight: 600;

				i {
					color: #999999;
				}
			}

			.type {
				color: #999999;
				font-size: 0.448rem;

				.svg-icon {
					color: #ff5000;
					font-size: 0.48rem;
					margin-right: 0.128rem;
				}
			}
		}

		.item-goods {
			display: flex;
			color: #333333;
			margin-bottom: 0.32rem;

			.goods-pic {
				flex-shrink: 0;
				width: 2.56rem;
				height: 2.56rem;
				border-radius: 0.32rem;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.goods-main {
				flex: 1;
				width: 0;
				margin: 0 0.32rem;

				.name {
					color: #333333;
					font-size: 0.416rem;
					height: 0.768rem;
					line-height: 0.768rem;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-weight: 600;
				}

				.desc {
					max-height: 1.088rem;
					line-height: 0.544rem;
					color: #999999;
					font-size: 0.416rem;
					display: -webkit-box;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					white-space: normal;
				}
			}

			.goods-box {
				flex-shrink: 0;
				text-align: right;

				.price {
					height: 0.768rem;
					line-height: 0.768rem;
					color: #333333;
					font-weight: 600;
					font-size: 0.352rem;

					span {
						font-size: 0.448rem;
					}
				}

				.number {
					height: 0.544rem;
					line-height: 0.544rem;
					color: #999999;
					font-size: 0.384rem;
				}
			}
		}

		.item-box {
			display: flex;
			align-items: center;
			line-height: 0.64rem;
			padding: 0.256rem 0;
			border-radius: 0.192rem;
			background-color: #f7f7f7;
			margin-bottom: 0.32rem;

			.cell-label {
				font-size: 0.416rem;
				font-weight: 600;
				width: 2.688rem;
				text-align: right;
			}

			.cell-block {
				flex: 1;
				width: 0;
				font-size: 0.384rem;
				margin-left: 0.384rem;
			}
		}

		.item-bottom {
			display: flex;
			justify-content: flex-end;
			padding-bottom: 0.256rem;

			.btn {
				margin-left: 0.384rem;
				color: #666666;
				height: 0.896rem;
				line-height: 0.896rem;
				padding: 0 0.384rem;
				font-size: 0.416rem;
				border: 1px solid #666666;
				border-radius: 0.512rem;

				&.btn-view {
					color: #ff5000;
					border-color: #ff5000;
				}
			}
		}
	}
}
</style>